<template>

  <div class="user">
    <h4>
      <span>常用地址</span>
      <img src="../../assets/signup/add.png" alt="">
      <span class="add" @click="add">添加</span>
    </h4>

    <div class="rbox">

      <div class="box" v-for="(item,i) in list" :key="i">
        <div class="b1">
          <p>{{item.name}}</p>
        </div>

        <div class="b1">
          <p>手机号</p>
          <p>{{item.phone}}</p>
        </div>
        <div class="b1">
          <p>地址</p>
          <p>{{item.province}}{{item.city== "市辖区" ? "" : item.city}}{{item.area== "市辖区" ? "" : item.area}}{{item.address}}</p>
        </div>
        <div class="b2 b1">
         
          <div class="edit">
            <img src="../../assets/user/edit2.png" alt="" @click="edit(item)">
            <img src="../../assets/user/del.png" alt="" @click="del(item.id)">
          </div>
        </div>
      </div>
    </div>

    <diaUserAdress ref="adress" @getlist="getlist"></diaUserAdress>
  </div>

</template>

<script>
  import {
    regionData,
    CodeToText,
    TextToCode
  } from "element-china-area-data";

  import {
    addressBillinglistAPI,
    addressdelAPI,
    addressAddAPI,
    addressEditAPI
  } from "@/api/user"
  import diaUserAdress from "@/components/com/diaUserAdress"
  export default {
    components:{
      diaUserAdress
    },  
    data() {
      return {
        sign: 1,
        list: [],
        selectedOptions: [],
        options: regionData,
        addflag: true

      }
    },
    created() {
      this.getlist()
    },
    methods: {
      add(){
        this.$refs.adress.dia=true
      },
      getlist() {
        console.log(11)
        addressBillinglistAPI({
          id: 'all'
        }).then(res => {
          this.list = res.user_address
        })
      },
      del(id) {
        this.$confirm('此操作将删除该地址, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                   addressdelAPI({
                      id
                    }).then(res => {
                      this.$message("删除地址成功！")
                      this.getlist()
                    })
                    }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
       
      },
     
      edit(item) {     
        var list = []
        list[2] = TextToCode[item.province][item.city][item.area].code
        list[1] = TextToCode[item.province][item.city].code
        list[0] = TextToCode[item.province].code
        
        this.$refs.adress.form1 = item
        this.$refs.adress.selectedOptions = list
        this.$refs.adress.addflag = false
        this.$refs.adress.dia=true
      }
    }
  }

</script>

<style lang="scss"  scoped="scoped">
  .user {
    padding: 5px 20px 20px;
    background: #ffffff;
    min-height: 500px;

    h4 {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      font-weight: 500;
      line-height: 45px;
      display: flex;
      align-items: center;

      img {
        margin-left: 40px;
        margin-right: 10px;
        width: 15px;
      }

      .add {
        color: #FF7C00;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .rbox {
      display: flex;
      flex-wrap: wrap;

      .box {
        width: 470px;
        border: 1px solid #e6e6e6;
        border-radius: 7px;
        margin: 10px;
        padding: 0 10px 10px;

        .b1 {
          display: flex;

          margin-top: 10px;

          p:nth-child(1) {
            color: #333;
            margin-right: 10px;
          }

          p:nth-child(2) {
            color: #666;
            margin-right: 10px;
          }

          >div {
            display: flex;
            align-items: center;
          }

          .edit {
            img {
              width: 20px;
              height:20px;
              margin: 0 10px;
            }
          }
        }

        .b2 {
          align-items: center;
          justify-content: space-between;
        }


      }

    }

    .el-dialog__body {
      padding: 0px 20px;
    }

    .fp {

      >p {
        line-height: 40px;
        font-size: 12px;
        color: #999;

        strong {
          font-size: 16px;
          margin-right: 15px;
        }
      }

      .fpform {
        >div {
          display: flex;
          align-items: center;
          line-height: 45px;
          color: #333333;

          p:nth-child(1) {
            width: 100px;
          }

          input {
            background: #f6f7f9;
            border-radius: 4px;
            width: 287px;
            height: 34px;
          }

          textarea {
            background: #f6f7f9;
            border-radius: 4px;
            width: 287px;
            height: 80px;
            padding: 10px;
          }

        }
      }

      .butt {
        p {
          width: 70px;
          line-height: 35px;
          opacity: 1;
          font-size: 14px;
          background: #ff7c00;
          border-radius: 2px;
          text-align: center;
          color: #ffffff;
          margin: 10px auto;
          cursor: pointer;
        }
      }
    }
  }

</style>
